/**
 * A type-safe wrapper around Element.closest().
 *
 * @param {*} el The source element.
 * @param {*} selectors A CSS selector with which to test the element and
 * its ancestors.
 * @returns {Element|null} The element or its closest ancestor that matches the
 * provided selectors, or null if no such element is found.
 */
export function closest(el, selectors) {
  if (el instanceof Element) {
    return el.closest(selectors);
  }
  if (el instanceof Node) {
    return closest(el.parentElement, selectors);
  }
  return null;
}

/**
 * A type-safe wrapper around `Node.contains()`.
 *
 * @param {*} node The parent Node.
 * @param {*} otherNode The possible descendant Node.
 * @returns {boolean} A boolean indicating whether or not `node` contains `otherNode`.
 */
export function contains(node, otherNode) {
  if (node instanceof Node && otherNode instanceof Node) {
    return node.contains(otherNode);
  }
  return false;
}

/**
 *
 * @param {*} fn
 * @param {*} interval
 * @returns
 */
export function throttle(fn, interval) {
  let t = performance.now();
  let timeout;
  return Object.assign(
    (...args) => {
      if (performance.now() - t >= interval) {
        fn(...args);
        t = performance.now();
        clearTimeout(timeout);
      } else {
        timeout = setTimeout(() => {
          fn(...args);
        }, interval);
      }
    },
    {
      cancel: () => {
        clearTimeout(timeout);
      },
    },
  );
}

/**
 * @param {Element} el
 */
export function wrapTextNodes(el) {
  let elements = [];
  getChildTextNodes(el).forEach((node) => {
    node.textContent.split(/(\s+)/g).forEach((str) => {
      if (!str.trim()) {
        node.parentNode.insertBefore(document.createTextNode(str), node);
      } else if (str === node.parentElement.textContent.trim()) {
        node.parentNode.insertBefore(document.createTextNode(str), node);
        elements.push(node.parentNode);
      } else {
        const span = document.createElement("span");
        span.textContent = str;
        node.parentNode.insertBefore(span, node);
        elements.push(span);
      }
    });
    node.parentNode.removeChild(node);
  });
  return elements;
}

/**
 *
 * @param {*} el
 * @returns {Text[]}
 */
function getChildTextNodes(el) {
  if (el instanceof Node) {
    return Array.from(el.childNodes).reduce((acc, node) => {
      if (node instanceof Text) {
        return acc.concat(node);
      }
      if (node.hasChildNodes()) {
        return acc.concat(getChildTextNodes(node));
      }
      return acc;
    }, []);
  }
  return [];
}
