/**
 * Main
 */

import * as motion from "motion";
import { closest, wrapTextNodes } from "./utilities";

const getVimeoPlayer = (() => {
  let vimeo;
  return async (el) => {
    if (!vimeo) {
      vimeo = import("@vimeo/player");
    }
    if (vimeo.then) {
      try {
        vimeo = (await vimeo).default;
      } catch (error) {
        return;
      }
    }
    if (typeof vimeo === "function") {
      try {
        return new vimeo(el);
      } catch (error) {
        // Ignore
      }
    }
  };
})();

(function (fn) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", () => fn());
  }
})(function () {
  document.addEventListener("click", (event) => {
    const button = closest(event.target, "[data-controls]");
    if (button instanceof HTMLElement && button.dataset.controls) {
      const target = document.getElementById(button.dataset.controls);
      if (target instanceof HTMLDialogElement) {
        target.showModal();
        let iframe = target.querySelector("iframe");
        if (iframe && iframe.src.includes("vimeo")) {
          getVimeoPlayer(iframe)
            .then((player) => {
              player.setLoop(true);
              player.play();
            })
            .catch(() => {
              // Ignore
            });
        }
      }
    }
    if (event.target instanceof HTMLDialogElement) {
      event.target.close();
    }
  });

  if ("IntersectionObserver" in window) {
    const observer = new IntersectionObserver((entries) =>
      entries.map(async (entry) => {
        const iframe = entry.target;
        if (
          iframe instanceof HTMLIFrameElement &&
          iframe.src.includes("vimeo")
        ) {
          const player = await getVimeoPlayer(iframe);
          if (player && !entry.isIntersecting) {
            player.pause();
            player.setCurrentTime(0);
          }
        }
      }),
    );
    document.querySelectorAll("iframe[src*='vimeo']").forEach((el) => {
      observer.observe(el);
    });
  }

  document.querySelectorAll("video[autoplay]").forEach((el) => {
    if (el instanceof HTMLVideoElement) {
      el.play().catch((error) => {
        console.log(error.name);
        if (error.name === "NotAllowedError" && el.poster) {
          const img = document.createElement("img");
          img.src = el.poster;
          img.height = el.height;
          img.width = el.width;
          img.className = el.className;
          el.replaceWith(img);
        }
      });
    }
  });

  document.querySelectorAll("[data-animate='value']").forEach((el) => {
    if (el instanceof HTMLElement) {
      const from = parseInt(el.dataset.from, 10);
      const to = parseInt(el.dataset.to, 10);
      if (Number.isInteger(from) && Number.isInteger(to)) {
        const scale = (n) => n * (to - from) + from;
        motion.inView(el, () => {
          motion.animate(
            (progress) => {
              el.innerHTML = Math.round(scale(progress)).toString();
            },
            { duration: 6, easing: [0.48, 0.75, 0, 1] },
          );
        });
      }
    }
  });

  document.querySelectorAll("[data-animate='text']").forEach((headline) => {
    wrapTextNodes(headline);

    headline
      .querySelectorAll(":where(em,span,img):not(:has(em,span,img))")
      .forEach((el) => {
        if (el instanceof HTMLElement) {
          Object.assign(el.style, {
            display: "inline-block",
          });
          motion.scroll(
            motion.animate(
              el,
              {
                opacity: [0, 1],
                transform: ["translateY(1em)", "translateY(0)"],
              },
              { easing: "ease-out" },
            ),
            { target: el, offset: ["start 0.875", "start center"] },
          );
        }
      });
  });

  document
    .querySelectorAll("[data-animate='children-in-view']")
    .forEach((el) => {
      if (el instanceof Element) {
        Array.from(el.children).forEach((child) => {
          if (
            child instanceof HTMLElement &&
            !child.dataset.animate &&
            !child.querySelector("[data-animate]")
          ) {
            Object.assign(child.style, { opacity: 0 });
            motion.inView(
              child,
              ({ target }) => {
                motion.animate(
                  target,
                  {
                    opacity: [0, 1],
                    transform: ["translateY(1.5rem)", "translateY(0)"],
                  },
                  // { opacity: [0, 1] },
                  { delay: 0.15, duration: 0.75, easing: "ease-out" },
                );
              },
              { margin: "-20% 0px 0px 0px" },
            );
          }
        });
      }
    });

  document.querySelectorAll("[data-animate='cursor']").forEach((el) => {
    if (
      !(el instanceof HTMLElement) ||
      !window.matchMedia("(pointer: fine)").matches
    ) {
      return;
    }

    const container =
      closest(el, "[data-container~='cursor']") || el.offsetParent;

    if (!(container instanceof HTMLElement)) {
      return;
    }

    const handleMouseMove = (event) => {
      const isFirst = !el.dataset.state;
      const rect = container.getBoundingClientRect();
      const x = event.clientX - rect.x - el.clientWidth / 2;
      const y = event.clientY - rect.y - el.clientHeight / 2;
      motion.animate(
        el,
        { x, y, scale: 1 },
        {
          duration: isFirst ? 0 : undefined,
          easing: isFirst
            ? undefined
            : motion.spring({ damping: 150, stiffness: 450 }),
          scale: { duration: 0.3 },
        },
      );
      el.dataset.state = "tracking";
    };

    container.addEventListener("mousemove", handleMouseMove);

    container.addEventListener("mouseleave", () => {
      motion.animate(el, { scale: 0.75 });
      delete el.dataset.state;
    });
  });

  document.querySelectorAll("[data-animate='parallax']").forEach((el) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    const container =
      closest(el, "[data-container~='parallax']") || el.offsetParent;

    if (!(container instanceof HTMLElement)) {
      return;
    }

    Object.assign(el.style, {
      height: "150%",
      transform: "translateY(var(--parallax-y))",
    });

    motion.scroll(
      ({ y }) => {
        let progress =
          (y.current - container.scrollTop) / container.clientHeight;
        progress = Math.min(1, Math.max(0, progress));
        const parallaxY = container.clientHeight * 0.5 * progress;
        container.style.setProperty("--parallax-y", `${parallaxY}px`);
      },
      { target: el },
    );
  });

  document
    .querySelectorAll(
      "[data-animate='text-in'], [data-animate='text-out'], [data-animate='text-in-out']",
    )
    .forEach((el) => {
      if (!(el instanceof HTMLElement)) {
        return;
      }

      if (el instanceof HTMLElement) {
        Object.assign(el.style, {
          display: "inline-block",
        });
        if (el.dataset.animate === "text-in") {
          motion.scroll(
            motion.animate(el, {
              opacity: [0, 1],
              transform: ["translateY(0.5em)", "translateY(0)"],
            }),
            {
              target: el,
              offset: ["start 0.75", "center center"],
            },
          );
        }
        if (el.dataset.animate === "text-in-out") {
          motion.scroll(
            motion.animate(el, {
              opacity: [0, 1, 0.125],
              transform: [
                "translateY(0.5em)",
                "translateY(0)",
                "translateY(0)",
              ],
            }),
            {
              target: el,
              offset: ["start 0.95", "center center", "center 0.35"],
            },
          );
        }
        if (el.dataset.animate === "text-out") {
          motion.scroll(
            motion.animate(el, { opacity: [1, 0.125] }, { easing: "ease-out" }),
            { target: el, offset: ["center 0.65", "center 0.45"] },
          );
        }
      }
    });

  let lottie;

  document.querySelectorAll("[data-animate='lottie']").forEach(async (el) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    if (!lottie) {
      lottie = import("lottie-web");
    }

    try {
      lottie = await lottie;
    } catch (error) {
      console.warn("Couldn't load lottie player");
    }

    if (
      typeof lottie !== "object" ||
      typeof lottie.loadAnimation !== "function"
    ) {
      return;
    }

    const lottieAnimation = lottie.loadAnimation({
      container: el,
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: el.dataset.file,
    });

    motion.inView(el, () => {
      lottieAnimation.play();
      return () => lottieAnimation.pause();
    });
  });

  document
    .querySelectorAll("[data-container='sticky-scroll-in']")
    .forEach((container) => {
      if (
        !(container instanceof HTMLElement) ||
        !("ResizeObserver" in window)
      ) {
        return;
      }

      const cards = Array.from(
        container.querySelectorAll("[data-animate='sticky-scroll-in']"),
      );

      const content = document.createElement("div");
      content.dataset.component = "sticky-scroll-content";

      const placeholder = document.createElement("div");
      placeholder.dataset.component = "sticky-scroll-placeholder";

      Object.assign(container.style, {
        display: "grid",
        gridTemplate: "auto / minmax(0,1fr)",
      });

      content.replaceChildren(...container.children);
      container.append(content, placeholder);

      /**
       * @param {{ enabled: boolean }} options
       */
      const setUp = ({ enabled }) => {
        if (enabled) {
          const size = cards.reduce((sum, el) => sum + el.clientHeight, 0);
          Object.assign(placeholder.style, { blockSize: `${size}px` });
          Object.assign(content.style, {
            justifyContent: "stretch",
            display: "flex",
            minBlockSize: "100vh",
            position: "sticky",
            top: "0px",
          });

          cards.forEach((card, index, arr) => {
            const start = 1 - (1 / arr.length) * index;
            const end = 1 - (1 / arr.length) * (index + 1);

            motion.scroll(
              motion.animate(
                card,
                {
                  opacity: [0, 1],
                  transform: [
                    `translateY(${card.clientHeight}px)`,
                    "translateY(0)",
                  ],
                },
                { easing: "ease-in-out" },
              ),
              {
                axis: "y",
                target: container,
                offset: [`start ${start}`, `center ${end}`],
              },
            );
          });
        }

        if (!enabled) {
          placeholder.removeAttribute("style");
          content.removeAttribute("style");
          cards.forEach((el) => el.removeAttribute("style"));
        }
      };

      const mql = window.matchMedia(
        "(min-height:  50em) and (min-width:  80em)",
      );

      if (typeof mql.addEventListener === "function") {
        mql.addEventListener("change", ({ matches }) =>
          setUp({ enabled: matches }),
        );
      } else {
        mql.addListener(({ matches }) => setUp({ enabled: matches }));
      }

      setUp({ enabled: mql.matches });
    });

  if ("ResizeObserver" in window) {
    const observer = new ResizeObserver((entries) =>
      entries.forEach((entry) => {
        if (
          entry.target instanceof Element &&
          entry.target.matches(".banner")
        ) {
          document.body.style.setProperty(
            "--size-banner-client-height",
            `${entry.target.clientHeight}px`,
          );
        }
      }),
    );
    document.querySelectorAll(".banner").forEach((el) => {
      observer.observe(el);
    });
  }
});

// Debug grid
Object.assign(window, {
  graybikeGrid() {
    let overlay = document.getElementById("graybike-debug-grid-overlay");

    if (!overlay) {
      overlay = document.createElement("div");
      overlay.id = "graybike-debug-grid-overlay";
      overlay.hidden = true;
      Object.assign(overlay.style, {
        blockSize: "100vw",
        display: "flex",
        inlineSize: "100vw",
        left: 0,
        pointerEvents: "none",
        position: "fixed",
        top: 0,
        zIndex: 1000,
      });

      const grid = document.createElement("div");
      Object.assign(grid.style, {
        display: "grid",
        gap: "var(--size-grid-gap)",
        gridTemplate: "auto / repeat(12, var(--size-grid-column))",
        inlineSize: "100%",
        margin: "0 auto",
        maxInlineSize: "var(--size-container-inline)",
      });

      grid.append(
        ...Array.from({ length: 12 }, () => {
          const el = document.createElement("div");
          Object.assign(el.style, { background: "hsl(0.2 90% 90% / 20%) " });
          return el;
        }),
      );

      overlay.append(grid);
      document.body.append(overlay);
    }

    overlay.hidden = !overlay.hidden;
  },
});
